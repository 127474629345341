import * as Sentry from '@sentry/react';
import { redirectSuccess } from '@/utils/flashMessage';
import client, { formError, FormResponse, formSuccess } from './client';

export type User = Awaited<ReturnType<typeof getUser>>;

export async function getUser() {
  const { data, error } = await client.GET('/user');

  if (!data) {
    console.error('Error getting user', error);
    Sentry.captureMessage('Error getting user', {
      level: 'error',
      extra: { error },
    });
    throw new Error('Failed to get user');
  }

  return data;
}

export async function forgotPassword(email: string): Promise<FormResponse> {
  const { error } = await client.POST('/user/resetpassword', {
    params: {
      query: {
        email,
      },
    },
  });

  if (error) {
    Sentry.captureMessage('Error requesting password reset', {
      level: 'error',
      extra: { error },
    });
    return formError('Something went wrong. Please try again.');
  }

  return formSuccess('Check your inbox for a password reset email.');
}

export async function resetPassword(
  email: string,
  passwordToken: string,
  password: string,
): Promise<FormResponse | Response> {
  const { error } = await client.POST('/user/confirmresetpassword', {
    body: {
      email,
      passwordToken,
      password,
    },
  });

  if (error) {
    Sentry.captureMessage('Error confirming password reset', {
      level: 'error',
      extra: { error },
    });
    return formError('Something went wrong. Please try again.');
  }

  return redirectSuccess(
    '/login',
    'Your password has been reset. Please sign in.',
  );
}
