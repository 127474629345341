import { useEffect, useRef } from 'react';
import { Input } from 'reactstrap';

export interface HeaderSelectionCheckboxProps {
  readonly id?: string;
  readonly selected: number;
  readonly total: number;
  readonly onSelectAll: () => void;
  readonly onClearAll: () => void;
}

export default function HeaderSelectionCheckbox({
  id,
  selected,
  total,
  onSelectAll,
  onClearAll,
}: HeaderSelectionCheckboxProps) {
  const checkboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!checkboxRef.current) return;

    if (selected === total) {
      checkboxRef.current.indeterminate = false;
      checkboxRef.current.checked = true;
    } else if (selected === 0) {
      checkboxRef.current.indeterminate = false;
      checkboxRef.current.checked = false;
    } else {
      checkboxRef.current.indeterminate = true;
    }
  }, [selected, total]);

  function onChange() {
    if (selected === total) {
      onClearAll();
    } else {
      onSelectAll();
    }
  }

  return (
    <Input id={id} type="checkbox" innerRef={checkboxRef} onChange={onChange} />
  );
}
