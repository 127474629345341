import { useToggle } from '@uidotdev/usehooks';
import { Input } from 'reactstrap';
import styled from 'styled-components';
import { Invoice } from '@/api/invoices';
import { formatLocationAddressShort } from '@/api/locations';
import InputWithPrefixSuffix from '@/components/InputWithPrefixSuffix';
import InvoiceStatusBadge from '@/components/InvoiceStatusBadge';
import TipInput from '@/components/TipInput';
import { formatDate } from '@/utils/dates';
import { formatAdditionalServices, parseNumberInput } from '@/utils/numbers';
import ToggleAdditionalServicesButton from './ToggleAdditionalServicesButton';

const AmountText = styled.span`
  display: inline-block;
  min-width: 120px;
`;

const AmountInput = styled(InputWithPrefixSuffix)`
  display: inline-flex;
  width: 120px;
`;

const StyledTipInput = styled(TipInput)`
  display: inline-flex;
  width: 250px;
`;

const NoteInput = styled(Input)`
  width: 350px;
`;

export interface InvoiceRowProps {
  readonly invoice: Invoice;
  readonly isSelected: boolean;
  readonly showTips: boolean;
  readonly tipAmount: string;
  readonly tipPercent: string;
  readonly additionalServicesAmount: string;
  readonly additionalServicesNote: string;
  readonly onToggleSelected: () => void;
  readonly onTipAmountChange: (tipAmount: string) => void;
  readonly onTipPercentChange: (tipAmount: string) => void;
  readonly onAdditionalServiceAmountChange: (
    additionalServiceAmount: string,
  ) => void;
  readonly onAdditionalServiceNoteChange: (
    additionalServiceNote: string,
  ) => void;
}

export default function InvoiceRow({
  invoice,
  isSelected,
  showTips,
  tipAmount,
  tipPercent,
  additionalServicesAmount,
  additionalServicesNote,
  onToggleSelected,
  onTipAmountChange,
  onTipPercentChange,
  onAdditionalServiceAmountChange,
  onAdditionalServiceNoteChange,
}: InvoiceRowProps) {
  const additionalServices = parseNumberInput(additionalServicesAmount);

  const invoiceSubtotal =
    invoice.balanceDue - invoice.salesTaxAmount + additionalServices;
  const hasAdditionalServices = additionalServices != 0;
  const isAdditionalServicesNoteRequired = hasAdditionalServices;

  const [showAdditionalServices, toggleShowAdditionalServices] =
    useToggle(false);

  return (
    <>
      <tr>
        <td className="align-middle">
          <Input
            name="invoiceIds[]"
            value={invoice.documentId}
            type="checkbox"
            checked={isSelected}
            onChange={onToggleSelected}
          />
          <input
            type="hidden"
            name={`franchiseId_${invoice.documentId}`}
            value={invoice.franchiseId}
          />
          <input
            type="hidden"
            name={`workOrderNumber_${invoice.documentId}`}
            value={invoice.workOrderNumber}
          />
          <input
            type="hidden"
            name={`tipAmount_${invoice.documentId}`}
            value={tipAmount}
          />
          <input
            type="hidden"
            name={`additionalServicesNote_${invoice.documentId}`}
            value={additionalServicesNote}
          />
          <input
            type="hidden"
            name={`additionalServicesAmount_${invoice.documentId}`}
            value={additionalServicesAmount}
          />
        </td>
        <td className="align-middle">
          <a
            href={invoice.pdfLink}
            target="_blank"
            rel="noreferrer"
            className="text-dark fw-bold"
          >
            {invoice.workOrderNumber}
          </a>
        </td>
        <td className="tabular-nums align-middle">
          {invoice.completedDate ? (
            formatDate(invoice.completedDate)
          ) : (
            <span className="text-secondary">Upcoming</span>
          )}
        </td>
        <td className="align-middle">{formatLocationAddressShort(invoice)}</td>
        <td className="tabular-nums align-middle text-end">
          <AmountText>
            <span>$ {invoice.balanceDue.toFixed(2)}</span>
            {hasAdditionalServices && (
              <span className="text-danger">
                {' '}
                {formatAdditionalServices(additionalServices)}
              </span>
            )}
          </AmountText>
        </td>
        <td className="align-middle">
          <InvoiceStatusBadge invoice={invoice} />
        </td>
        {showTips && (
          <td>
            {invoice.tipsEnabled && (
              <StyledTipInput
                invoiceSubtotal={invoiceSubtotal}
                tipAmount={tipAmount}
                tipPercent={tipPercent}
                onTipAmountChange={onTipAmountChange}
                onTipPercentChange={onTipPercentChange}
              />
            )}
          </td>
        )}
        <td className="align-middle text-end">
          <ToggleAdditionalServicesButton
            showAdditionalServices={showAdditionalServices}
            hasAdditionalServices={hasAdditionalServices}
            onToggleShowAdditionalServices={toggleShowAdditionalServices}
          />
        </td>
      </tr>
      {showAdditionalServices && (
        <tr>
          <td></td>
          <td className="align-middle text-secondary fst-italic" colSpan={2}>
            Additional services
          </td>
          <td></td>
          <td className="text-end">
            <AmountInput
              size={1}
              prefix="$"
              placeholder="0.00"
              value={additionalServicesAmount}
              onChange={(event) =>
                onAdditionalServiceAmountChange(event.target.value)
              }
            />
          </td>
          <td colSpan={showTips ? 2 : 1}>
            <NoteInput
              size={1}
              type="text"
              placeholder="Note (required)"
              required={isAdditionalServicesNoteRequired}
              value={additionalServicesNote}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onAdditionalServiceNoteChange(event.target.value)
              }
              disabled={!hasAdditionalServices}
            />
          </td>
          <td></td>
        </tr>
      )}
    </>
  );
}
