import { FaArrowLeft, FaPlus } from 'react-icons/fa6';
import { NavLink, Outlet, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import LinkButton from '@/components/LinkButton';
import PageHeader from '@/components/PageHeader';
import PaymentMethodsSidebar from '@/features/payment-methods/PaymentMethodsSidebar';
import { usePaymentMethodsLoaderData } from './layout.loader';

const OuterWrapper = styled.div`
  flex-grow: 1;
  max-width: 80rem;
  margin-inline: auto;
  width: 100%;
  padding-inline: 1rem;
  padding-block: 2rem;
  display: flex;
  flex-direction: column;
`;

const SidebarAndContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  gap: 3rem;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const CenteredContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  margin-inline: auto;
`;

export default function PaymentMethodsLayout() {
  const { hasLocations, paymentMethods } = usePaymentMethodsLoaderData();
  const path = useLocation().pathname;
  const isAddPaymentMethodPage = path === '/payment-methods/add';
  const { paymentMethodId: selectedPaymentMethodId } = useParams();
  const showBackButton =
    selectedPaymentMethodId != null || isAddPaymentMethodPage;
  const showAddButton = hasLocations && !isAddPaymentMethodPage;

  return (
    <OuterWrapper>
      {showBackButton && (
        <NavLink
          to="/payment-methods"
          className="d-lg-none text-secondary text-decoration-none d-flex align-items-center gap-2"
        >
          <FaArrowLeft />
          All payment methods
        </NavLink>
      )}

      <PageHeader title="Payment Methods">
        {showAddButton && (
          <LinkButton
            to="/payment-methods/add"
            color="primary"
            className="d-none d-lg-inline-flex"
          >
            <FaPlus />
            Add Payment Method
          </LinkButton>
        )}
      </PageHeader>

      <SidebarAndContentWrapper>
        {paymentMethods.length > 0 ? (
          <>
            <PaymentMethodsSidebar paymentMethods={paymentMethods} />
            <ContentWrapper>
              <Outlet />
            </ContentWrapper>
          </>
        ) : (
          <CenteredContentWrapper>
            <Outlet />
          </CenteredContentWrapper>
        )}
      </SidebarAndContentWrapper>
    </OuterWrapper>
  );
}
