import * as Sentry from '@sentry/react';
import { redirectSuccess } from '@/utils/flashMessage';
import client, { formError, formSuccess } from './client';

export type Invitation = Awaited<ReturnType<typeof getInvitations>>[number];

export async function getInvitations() {
  const { data, error } = await client.GET('/user/invitations');

  if (!data) {
    console.error('Error fetching invitations', error);
    Sentry.captureMessage('Error fetching invitations', {
      level: 'error',
      extra: { error },
    });
    throw new Error('Unable to fetch invitations');
  }

  return data;
}

export async function acceptInvitations() {
  const { error } = await client.POST('/user/invitations');

  if (error) {
    console.error('Error accepting invitations', error);
    Sentry.captureMessage('Error accepting invitations', {
      level: 'error',
      extra: { error },
    });
    throw new Error('Unable to accept invite');
  }

  return formSuccess('Invite accepted.');
}

export async function acceptInvite(
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  passwordToken: string,
) {
  const { error } = await client.POST('/user/acceptinvite', {
    body: {
      email,
      firstName,
      lastName,
      password,
      passwordToken,
    },
  });

  if (error) {
    Sentry.captureMessage('Error accepting invite', {
      level: 'error',
      extra: { error },
    });

    return formError('Something went wrong. Please try again.');
  }

  return redirectSuccess(
    '/login',
    'Thanks for accepting the invite! You can now log in with your new account.',
  );
}
