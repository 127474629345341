import { ActionFunctionArgs } from 'react-router-dom';
import { acceptInvitations } from '@/api/invitations';
import { removeLocation } from '@/api/locations';
import { CustomerPortalWorkOrderRequest, loggedInSale } from '@/api/payments';

export default async function invoicesAction({
  request,
  params,
}: ActionFunctionArgs) {
  const formData = await request.formData();
  const action = formData.get('action') as string;

  if (action === 'pay') {
    const paymentMethodId = formData.get('paymentMethodId') as string;
    const sendReceipt = formData.get('sendReceipt') === 'on';
    const invoiceIds = formData.getAll('invoiceIds[]') as string[];

    return loggedInSale({
      paymentMethodId,
      sendReceipt,
      workOrders: invoiceIds.map((id) => {
        const franchiseId = formData.get(`franchiseId_${id}`) as string;
        const workOrderNumber = formData.get(`workOrderNumber_${id}`) as string;
        const additionalServicesNote = formData.get(
          `additionalServicesNote_${id}`,
        ) as string | null;
        const additionalServicesAmount = formData.get(
          `additionalServicesAmount_${id}`,
        ) as string | null;
        const tipAmount = formData.get(`tipAmount_${id}`) as string | null;

        return {
          franchiseId: parseInt(franchiseId),
          workOrderNumber: parseInt(workOrderNumber),
          additionalServicesNote,
          additionalServiceAmount: additionalServicesAmount
            ? parseFloat(additionalServicesAmount)
            : 0,
          tipAmount: tipAmount ? parseFloat(tipAmount) : 0,
        } satisfies CustomerPortalWorkOrderRequest;
      }),
    });
  } else if (action === 'delete_location') {
    const locationId = parseInt(params.locationId!);

    return removeLocation(locationId);
  } else if (action.startsWith('delete_location_')) {
    const locationId = parseInt(action.split('_')[2]!, 10);
    return removeLocation(locationId);
  } else if (action === 'accept_invitations') {
    await acceptInvitations();
  } else {
    throw new Error(`Unknown action: ${action}`);
  }
}
