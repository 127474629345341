import { Form, useActionData, useParams } from 'react-router-dom';
import { FormResponse } from '@/api/client';
import ButtonRow from '@/components/ButtonRow';
import FormResponseAlert from '@/components/FormResponseAlert';
import StyledCard from '@/components/StyledCard';
import SubmitButton from '@/components/SubmitButton';
import { usePaymentMethodsLoaderData } from './layout.loader';

export default function PaymentMethodDetails() {
  const { paymentMethods } = usePaymentMethodsLoaderData();
  const { paymentMethodId } = useParams();
  const actionData = useActionData<FormResponse>();

  const paymentMethod = paymentMethods.find((pm) => pm.id === paymentMethodId)!;

  return (
    <StyledCard title="Payment Method Details">
      <FormResponseAlert response={actionData} />
      <Form method="post">
        <p>{paymentMethod.maskedCard}</p>

        <ButtonRow className="mt-3">
          <SubmitButton color="danger">Delete Payment Method</SubmitButton>
        </ButtonRow>
      </Form>
    </StyledCard>
  );
}
