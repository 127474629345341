import * as Sentry from '@sentry/react';
import { redirectSuccess } from '@/utils/flashMessage';
import client, { formError } from './client';
import { components } from './openapi-schema';

export type PaymentMethod = Awaited<
  ReturnType<typeof getPaymentMethods>
>[number];

export type NewPaymentMethod =
  components['schemas']['PortalPaymentMethodInputDTO'];

export async function getPaymentMethods() {
  const { data, error } = await client.GET('/usaepay/portal/paymentmethods');

  if (!data) {
    console.error('Error fetching payment methods', error);
    Sentry.captureMessage('Error fetching payment methods', {
      level: 'error',
      extra: { error },
    });
    throw new Error('Unable to fetch payment methods');
  }

  return data;
}

export async function addPaymentMethod(paymentMethod: NewPaymentMethod) {
  const { error } = await client.POST('/usaepay/portal/paymentmethods', {
    body: paymentMethod,
  });

  if (error) {
    console.error('Error adding payment method', error);
    Sentry.captureMessage('Error adding payment method', {
      level: 'error',
      extra: { error },
    });
    return formError(`Unable to add payment method: ${error.detail}`);
  }

  return redirectSuccess('/payment-methods', 'Payment method added');
}

export async function removePaymentMethod(paymentMethodId: string) {
  const { error, response } = await client.DELETE(
    '/usaepay/portal/paymentmethods/{id}',
    {
      params: {
        path: { id: paymentMethodId },
      },
    },
  );

  if (!response.ok) {
    console.error('Error removing payment method', error);
    Sentry.captureMessage('Error removing payment method', {
      level: 'error',
      extra: {
        error,
        status: response.status,
        statusText: response.statusText,
      },
    });
    return formError('Unable to remove payment method');
  }

  return redirectSuccess('/payment-methods', 'Payment method removed');
}
