import { useState } from 'react';
import { useSubmit } from 'react-router-dom';
import usePaymentCard from './usePaymentCard';

export default function usePaymentCardForm() {
  const submit = useSubmit();

  const {
    paymentCard,
    errorMessage: cardError,
    getPaymentKey,
  } = usePaymentCard();

  const [isBusy, setIsBusy] = useState(false);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setIsBusy(true);

    try {
      const formData = new FormData(event.currentTarget);
      const paymentKey = await getPaymentKey();

      if (!paymentKey) return;

      formData.set('paymentKey', paymentKey.key);
      formData.set('cardType', paymentKey.creditcard.cardtype);
      formData.set('maskedCard', paymentKey.creditcard.number);

      await submit(formData, {
        method: 'POST',
      });
    } finally {
      setIsBusy(false);
    }
  }

  return {
    paymentCard,
    cardError,
    isBusy,
    handleSubmit,
  };
}
