import * as Sentry from '@sentry/react';
import { redirect } from 'react-router-dom';
import { redirectSuccess } from '@/utils/flashMessage';
import client, { formError } from './client';
import { components } from './openapi-schema';

export type GuestSaleRequest = components['schemas']['GuestSaleRequest'];

export type CustomerPortalSaleRequest =
  components['schemas']['CustomerPortalSaleRequest'];
export type CustomerPortalWorkOrderRequest =
  components['schemas']['CustomerPortalWorkOrderRequest'];

export async function guestSale(request: GuestSaleRequest) {
  const { error } = await client.POST('/usaepay/guestsale', {
    body: request,
  });

  if (error?.status === 404) {
    return formError(
      'Invoice not found. Please check your information and try again.',
    );
  }

  if (error) {
    console.error('Error making guest payment', error);
    Sentry.captureMessage('Error making guest payment', {
      level: 'error',
      extra: { error },
    });

    if (error.detail) {
      return formError(`Unable to complete payment: ${error.detail}`);
    } else {
      return formError('Something went wrong. Please try again.');
    }
  }

  return redirect('/guest-payment/success');
}

export async function loggedInSale(request: CustomerPortalSaleRequest) {
  const { error } = await client.POST('/usaepay/customerportalsale', {
    body: request,
  });

  if (error?.status === 404) {
    return formError(
      error.detail ?? 'Unable to pay invoices. Please try again.',
    );
  }

  if (error) {
    console.error('Error making payment', error);
    Sentry.captureMessage('Error making payment', {
      level: 'error',
      extra: { error },
    });

    if (error.detail) {
      return formError(`Unable to complete payment: ${error.detail}`);
    } else {
      return formError('Something went wrong. Please try again.');
    }
  }

  return redirectSuccess('/invoices', 'Payment successful!');
}
