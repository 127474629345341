import { useToggle } from '@uidotdev/usehooks';
import { FaPlus } from 'react-icons/fa6';
import {
  NavLink as BootstrapNavLink,
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
} from 'reactstrap';
import styled from 'styled-components';
import fishLogo from '@/assets/fish-logo.svg';
import NavLink from './NavLink';

const FishLogo = styled.img`
  height: 2rem;
  margin-left: 0.5rem;
  margin-right: 1rem;
`;

interface AppNavbarProps {
  readonly onLogOutClick: () => void;
}

export default function AppNavbar({ onLogOutClick }: AppNavbarProps) {
  const [isOpen, toggle] = useToggle(false);

  return (
    <Navbar color="white" light expand="md" className="shadow-sm">
      <NavbarBrand>
        <FishLogo src={fishLogo} alt="Fish Window Cleaning" />
        Customer Payments Portal
      </NavbarBrand>
      <NavbarToggler onClick={() => toggle()} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="me-auto" navbar>
          <NavItem>
            <NavLink to="/invoices">Invoices</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/locations/add">
              <FaPlus /> Add Location
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/payment-methods">Payment Methods</NavLink>
          </NavItem>
        </Nav>

        <Nav navbar>
          <NavItem>
            <BootstrapNavLink
              tag="button"
              onClick={onLogOutClick}
              className="text-danger"
            >
              Log out
            </BootstrapNavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
}
