import { FaTrash } from 'react-icons/fa6';
import { Nav, NavItem } from 'reactstrap';
import styled from 'styled-components';
import { PaymentMethod } from '@/api/paymentMethods';
import NavLink from '@/components/NavLink';
import { breakpointDown } from '@/utils/mediaQueries';

const StyledNav = styled(Nav)`
  flex-shrink: 0;
  align-self: flex-start;
  width: 360px;
  background-color: var(--bs-gray-200) !important;
  border-radius: 0.5rem;

  > :not(:last-child):not(:has(.active)):not(:has(+ li > .active)) {
    border-bottom: 1px solid var(--bs-gray-400);
  }

  ${breakpointDown('lg')} {
    display: none;
  }
`;

const SidebarNavLink = styled(NavLink)`
  color: var(--bs-gray-800);
  border-radius: 0.5rem !important;
  padding: 1.5rem 2rem;

  &.active {
    color: var(--bs-primary) !important;
    background-color: white !important;
    border: 1px solid var(--bs-gray-400);
    box-shadow:
      0 4px 6px -1px rgb(0 0 0 / 0.1),
      0 2px 4px -2px rgb(0 0 0 / 0.1);
  }
`;

export interface PaymentMethodsSidebarProps {
  readonly paymentMethods: PaymentMethod[];
}

export default function PaymentMethodsSidebar({
  paymentMethods,
}: PaymentMethodsSidebarProps) {
  return (
    <StyledNav pills vertical>
      {paymentMethods.map((paymentMethod) => (
        <NavItem key={paymentMethod.id}>
          <SidebarNavLink
            to={`/payment-methods/${paymentMethod.id}`}
            className="position-relative"
          >
            <div>Credit Card</div>
            <div className="text-secondary">{paymentMethod.maskedCard}</div>

            <div className="position-absolute top-0 end-0 p-3 d-flex">
              <FaTrash className="text-secondary" />
            </div>
          </SidebarNavLink>
        </NavItem>
      ))}
    </StyledNav>
  );
}
