import { useState } from 'react';
import { Button, Input, InputGroup, InputGroupText } from 'reactstrap';
import styled from 'styled-components';
import { parseNumberInput } from '@/utils/numbers';

enum TipPercentOrDollars {
  Percent,
  Dollars,
}

export interface TipInputProps {
  readonly className?: string;
  readonly invoiceSubtotal: number;
  readonly tipAmount: string;
  readonly tipPercent: string;
  readonly onTipAmountChange: (amount: string) => void;
  readonly onTipPercentChange: (percent: string) => void;
}

const TipInputGroup = styled(InputGroup)`
  &:has(input:disabled) {
    opacity: 0.5;
  }
`;

export default function TipInput({
  className,
  invoiceSubtotal,
  tipAmount,
  tipPercent,
  onTipAmountChange,
  onTipPercentChange,
}: TipInputProps) {
  const [type, setType] = useState(TipPercentOrDollars.Percent);

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value === '') {
      onTipAmountChange('');
      onTipPercentChange('');
    } else {
      const value = parseNumberInput(event.target.value);

      if (type == TipPercentOrDollars.Percent) {
        onTipPercentChange(event.target.value);
        onTipAmountChange(((value / 100) * invoiceSubtotal).toFixed(2));
      } else {
        onTipAmountChange(event.target.value);
        onTipPercentChange(((value / invoiceSubtotal) * 100).toFixed(0));
      }
    }
  }

  return (
    <div>
      <TipInputGroup className={className}>
        <Button
          color="outline-secondary"
          active={type == TipPercentOrDollars.Percent}
          onClick={() => setType(TipPercentOrDollars.Percent)}
        >
          %
        </Button>
        <Button
          color="outline-secondary"
          active={type == TipPercentOrDollars.Dollars}
          onClick={() => setType(TipPercentOrDollars.Dollars)}
        >
          $
        </Button>
        <Input
          value={type == TipPercentOrDollars.Percent ? tipPercent : tipAmount}
          placeholder={type == TipPercentOrDollars.Percent ? '0' : '0.00'}
          onChange={onChange}
        />

        {tipPercent !== '' && tipAmount !== '' && (
          <InputGroupText>
            {type == TipPercentOrDollars.Dollars
              ? tipPercent + '%'
              : '$' + tipAmount}
          </InputGroupText>
        )}
      </TipInputGroup>
      {parseNumberInput(tipAmount) > invoiceSubtotal && (
        <div className="text-danger mt-1">
          Thank you for your generosity! We noticed that the tip exceeds the
          cost of the job. Was this your intention?
        </div>
      )}
    </div>
  );
}
