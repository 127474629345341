import { Form } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import ButtonRow from '@/components/ButtonRow';
import CreditCardField from '@/components/CreditCardField';
import FlashMessage from '@/components/FlashMessage';
import FormInput from '@/components/FormInput';
import LinkButton from '@/components/LinkButton';
import StyledCard from '@/components/StyledCard';
import SubmitButton from '@/components/SubmitButton';
import usePaymentCardForm from '@/hooks/usePaymentCardForm';

export default function AddPaymentMethod() {
  const { paymentCard, cardError, isBusy, handleSubmit } = usePaymentCardForm();

  return (
    <StyledCard title="Add Payment Method">
      <FlashMessage className="mb-3" />

      <Form onSubmit={(event) => void handleSubmit(event)}>
        <Row>
          <Col md={9}>
            <FormInput name="street" label="Street Address" required />
          </Col>

          <Col md={3}>
            <FormInput name="postalCode" label="Zip Code" required />
          </Col>

          <Col xs={12}>
            <CreditCardField
              paymentCard={paymentCard}
              errorMessage={cardError}
            />
          </Col>

          <Col xs={12}>
            <ButtonRow className="mt-3">
              <SubmitButton color="primary" size="lg" busy={isBusy}>
                Save Card
              </SubmitButton>
              <LinkButton to="/payment-methods" color="secondary">
                Cancel
              </LinkButton>
            </ButtonRow>
          </Col>
        </Row>
      </Form>
    </StyledCard>
  );
}
