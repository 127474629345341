import { FaCreditCard, FaPlus, FaTrash } from 'react-icons/fa6';
import { Nav, NavItem } from 'reactstrap';
import styled from 'styled-components';
import LinkButton from '@/components/LinkButton';
import NavLink from '@/components/NavLink';
import { usePaymentMethodsLoaderData } from './layout.loader';

const PlaceholderWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const StyledNavLink = styled(NavLink)`
  color: var(--bs-gray-800);
  background-color: var(--bs-gray-200) !important;
  padding: 1.5rem 2rem;
`;

export default function PaymentMethodsIndex() {
  const { hasLocations, paymentMethods } = usePaymentMethodsLoaderData();

  if (!hasLocations) {
    return (
      <PlaceholderWrapper>
        <FaCreditCard size={100} className="mb-4 text-secondary" />
        <h1>No locations</h1>
        <p>Please add a location before you can add a payment method.</p>
      </PlaceholderWrapper>
    );
  }
  if (paymentMethods.length > 0) {
    return (
      <>
        <div className="d-lg-none">
          <LinkButton
            to="/payment-methods/add"
            color="primary"
            className="mb-4"
          >
            <FaPlus />
            Add payment method
          </LinkButton>
          <Nav pills vertical>
            {paymentMethods.map((paymentMethod) => (
              <NavItem key={paymentMethod.id}>
                <StyledNavLink
                  to={`/payment-methods/${paymentMethod.id}`}
                  className="position-relative"
                >
                  <div>Credit Card</div>
                  <div className="text-secondary">
                    {paymentMethod.maskedCard}
                  </div>

                  <div className="position-absolute top-0 end-0 p-3 d-flex">
                    <FaTrash className="text-secondary" />
                  </div>
                </StyledNavLink>
              </NavItem>
            ))}
          </Nav>
        </div>
        <PlaceholderWrapper className="d-none d-lg-flex">
          <FaCreditCard size={100} className="mb-4 text-secondary" />
          <h1>Manage payment methods</h1>
          <p>
            Add a new payment method or select a payment method to edit or
            remove it.
          </p>
        </PlaceholderWrapper>
      </>
    );
  } else {
    return (
      <PlaceholderWrapper>
        <FaCreditCard size={100} className="mb-4 text-secondary" />
        <h1>No Payment Methods</h1>
        <p>
          You have no payment methods on file. Please add one to be able to pay
          invoices.
        </p>
      </PlaceholderWrapper>
    );
  }
}
