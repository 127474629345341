import { Nav, NavItem } from 'reactstrap';
import styled from 'styled-components';
import { formatLocationAddressShort, Location } from '@/api/locations';
import { breakpointDown } from '@/utils/mediaQueries';
import NavLink from './NavLink';

const Wrapper = styled.div`
  width: 360px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding-block: 2rem;

  ${breakpointDown('xxl')} {
    display: none;
  }
`;

const Subheading = styled.h5`
  font-size: 1em;
  margin-top: 2rem;
  padding-inline: 1rem;
  color: var(--bs-gray-500);
`;

const SidebarNavLink = styled(NavLink)`
  color: var(--bs-gray-800);

  &.active {
    color: var(--bs-primary) !important;
    background-color: var(--bs-gray-200) !important;
  }
`;

export interface AppSidebarProps {
  readonly locations: Location[];
  readonly outstandingBalance: number;
}

export default function AppSidebar({
  locations,
  outstandingBalance,
}: AppSidebarProps) {
  return (
    <Wrapper>
      <div className="mb-3 mx-3">
        <div className="fw-bold">Outstanding Balance:</div>
        <div className="h1 text-danger fw-bold">
          $ {outstandingBalance.toFixed(2)}
        </div>
      </div>

      <Nav pills vertical className="flex-grow-1">
        <NavItem>
          <SidebarNavLink to="/invoices">All Unpaid Invoices</SidebarNavLink>
        </NavItem>

        <Subheading>Locations</Subheading>

        {locations.map((location) => (
          <NavItem key={location.locationId}>
            <SidebarNavLink to={`/locations/${location.locationId}`}>
              <div>{location.description}</div>
              <div className="text-secondary">
                {formatLocationAddressShort(location)}
              </div>
            </SidebarNavLink>
          </NavItem>
        ))}
      </Nav>
    </Wrapper>
  );
}
