import { Outlet, useNavigate } from 'react-router-dom';
import { logOut } from '@/api/auth';
import AppNavbar from '@/components/AppNavbar';
import PageFooter from '@/components/PageFooter';

export default function LoggedInLayout() {
  const navigate = useNavigate();

  async function logOutAndRedirect() {
    logOut();
    await navigate('/');
  }

  return (
    <>
      <AppNavbar onLogOutClick={() => void logOutAndRedirect()} />

      <Outlet />

      <PageFooter />
    </>
  );
}
