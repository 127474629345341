import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import styled from 'styled-components';

const StyledCardWrapper = styled(Card)`
  overflow: hidden;
`;

const StyledCardHeader = styled(CardHeader)`
  background-color: var(--bs-gray-200);
  padding-block: 0.75rem;
  border-radius: 0 !important;
`;

const StyledCardBody = styled(CardBody)<{ $padded: boolean }>`
  padding: ${(props) => (props.$padded ? '1.5rem' : '0')};
`;

export interface StyledCardProps {
  readonly title: string;
  readonly children?: React.ReactNode;
  readonly padded?: boolean;
}

export default function StyledCard({
  title,
  children,
  padded = true,
}: StyledCardProps) {
  return (
    <StyledCardWrapper>
      <StyledCardHeader tag="h5">{title}</StyledCardHeader>
      <StyledCardBody $padded={padded}>{children}</StyledCardBody>
    </StyledCardWrapper>
  );
}
