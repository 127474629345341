import { useMap, useSet } from '@uidotdev/usehooks';
import { useRef, useState } from 'react';
import {
  FaCreditCard,
  FaDownLong,
  FaGear,
  FaPlus,
  FaRegCircleQuestion,
  FaSliders,
} from 'react-icons/fa6';
import { Form, NavLink, useParams } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import styled from 'styled-components';
import { Invoice } from '@/api/invoices';
import { formatLocationAddressShort } from '@/api/locations';
import FlashMessage from '@/components/FlashMessage';
import HeaderSelectionCheckbox from '@/components/HeaderSelectionCheckbox';
import LinkButton from '@/components/LinkButton';
import MobileLocationsNav from '@/components/MobileLocationsNav';
import PageHeader from '@/components/PageHeader';
import PaymentSummaryTable from '@/components/PaymentSummaryTable';
import SubmitButton from '@/components/SubmitButton';
import InvoiceRow from '@/features/invoices/InvoiceRow';
import MobileInvoiceCard from '@/features/invoices/MobileInvoiceCard';
import { parseNumberInput } from '@/utils/numbers';
import { useDashboardLayoutLoaderData } from './layout.loader';

const TIP_PERCENTS = [0.15, 0.2];

const TableWrapper = styled.div``;

const StyledTable = styled(Table)`
  margin: 0;
  border-collapse: separate;
  border-spacing: 0;

  thead th:first-child {
    border-top-left-radius: 8px;
  }

  thead th:last-child {
    border-top-right-radius: 8px;
  }

  tbody tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }

  tbody tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }

  thead th:first-child,
  tbody td:first-child {
    border-left: 1px solid var(--bs-table-border-color);
  }

  thead th:last-child,
  tbody td:last-child {
    border-right: 1px solid var(--bs-table-border-color);
  }

  thead th {
    border-top: 1px solid var(--bs-table-border-color);
  }

  tbody tr:last-child td {
    border-bottom: 1px solid var(--bs-table-border-color);
  }

  th:first-child,
  td:first-child {
    padding-left: 1rem;
  }

  th:last-child,
  td:last-child {
    padding-right: 1rem;
  }

  thead th {
    background: var(--bs-gray-100);
  }

  th.status-column {
    width: 100px;
  }

  tfoot td {
    border-bottom: 0;
    background: transparent;
  }
`;

const StyledPaymentSummaryTable = styled(PaymentSummaryTable)`
  max-width: 400px;
  margin-left: auto;

  th,
  td {
    background: transparent;
  }
`;

const StyledMobileTableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 1rem;
  margin-bottom: 1rem;
  color: var(--bs-gray-600);
`;

const StyledJumpButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0;
  text-decoration: none;
  font-weight: 500;
  color: var(--bs-gray-600);
`;

const PaymentMethodUncontrolledDropdown = styled(UncontrolledDropdown)`
  background-color: white;
`;

function useCurrentLocation() {
  const {
    locations: allLocations,
    invoices: allInvoices,
    paymentMethods,
  } = useDashboardLayoutLoaderData();
  const { locationId: locationIdString } = useParams();

  const locationId =
    locationIdString != null ? parseInt(locationIdString) : null;

  if (locationId == null) {
    return {
      allLocations,
      location: null,
      invoices: allInvoices,
      paymentMethods,
    };
  } else {
    const location = allLocations.find((l) => l.locationId === locationId);

    if (location == null) {
      throw new Error(`Location not found: ${locationId}`);
    }

    const invoices = allInvoices.filter((invoice) =>
      invoice.workOrders.some((w) => w.locationId === locationId),
    );

    return { allLocations, location, invoices, paymentMethods };
  }
}

export default function InvoicesPage() {
  const { allLocations, location, invoices, paymentMethods } =
    useCurrentLocation();

  const outstandingBalance = invoices.reduce(
    (total, invoice) => total + invoice.balanceDue,
    0,
  );

  const [showLocations, setShowLocations] = useState(false);
  const [showHelp, setShowHelp] = useState(false);

  const selectedInvoiceIds = useSet(
    invoices.map((invoice) => invoice.documentId),
  );

  const additionalServiceAmounts = useMap<number>() as Map<number, string>;
  const additionalServiceNotes = useMap<number>() as Map<number, string>;
  const invoiceTipPercents = useMap<number>() as Map<number, string>;
  const invoiceTipAmounts = useMap<number>() as Map<number, string>;

  const showTips = invoices.some((invoice) => invoice.tipsEnabled);

  const selectedInvoices = invoices.filter((invoice) =>
    selectedInvoiceIds.has(invoice.documentId),
  );

  const invoiceAmounts = selectedInvoices.map((invoice) =>
    getInvoiceAmounts(invoice, additionalServiceAmounts, invoiceTipAmounts),
  );

  const invoiceTotal = invoiceAmounts.reduce(
    (sum, invoice) => sum + invoice.balanceDue,
    0,
  );

  const additionalServices = invoiceAmounts.reduce(
    (sum, invoice) => sum + invoice.additionalServices,
    0,
  );

  const [paymentMethodId, setPaymentMethodId] = useState<string | null>(
    paymentMethods.length === 1 ? paymentMethods[0]!.id : null,
  );

  const selectedPaymentMethod = paymentMethods.find(
    (method) => method.id === paymentMethodId,
  );

  const tips = invoiceAmounts.reduce((sum, invoice) => sum + invoice.tip, 0);

  const salesTax = invoiceAmounts.reduce(
    (sum, invoice) => sum + invoice.salesTax,
    0,
  );

  const summaryTableRef = useRef<HTMLTableElement>(null);

  function toggleInvoiceSelection(invoiceId: number) {
    if (selectedInvoiceIds.has(invoiceId)) {
      selectedInvoiceIds.delete(invoiceId);
      additionalServiceAmounts.delete(invoiceId);
      invoiceTipAmounts.delete(invoiceId);
    } else {
      selectedInvoiceIds.add(invoiceId);
    }
  }

  function selectAllInvoices() {
    invoices.forEach((invoice) => selectedInvoiceIds.add(invoice.documentId));
  }

  function setTipPercentForAllInvoices(percent: number) {
    if (allInvoicesHaveTipPercent(percent)) {
      invoiceTipPercents.clear();
      invoiceTipAmounts.clear();
    } else {
      selectedInvoices.forEach((invoice) => {
        invoiceTipPercents.set(invoice.documentId, (percent * 100).toFixed(0));
        invoiceTipAmounts.set(
          invoice.documentId,
          (percent * invoice.balanceDue).toFixed(2),
        );
      });
    }
  }

  function allInvoicesHaveTipPercent(percent: number) {
    return selectedInvoices.every(
      (invoice) =>
        invoiceTipPercents.get(invoice.documentId) ===
        (percent * 100).toFixed(0),
    );
  }

  function toggleShowHelp() {
    if (showHelp) {
      setShowHelp(false);
    } else {
      setShowLocations(false);
      setShowHelp(true);
    }
  }

  function toggleShowLocations() {
    if (showLocations) {
      setShowLocations(false);
    } else {
      setShowHelp(false);
      setShowLocations(true);
    }
  }

  return (
    <Form method="POST">
      <div className="px-3 px-xl-0">
        <FlashMessage className="mb-3" />
        <PageHeader
          className="d-none d-xxl-block"
          title={location?.description ?? 'All Unpaid Invoices'}
          subtitle={
            location != null ? formatLocationAddressShort(location) : null
          }
        >
          <LinkButton to="/locations/add">
            <FaPlus /> Add Location
          </LinkButton>

          {location && (
            <UncontrolledDropdown>
              <DropdownToggle color="outline-secondary" className="icon-btn">
                <FaGear />
              </DropdownToggle>
              <DropdownMenu end>
                {/* Need to use a normal button here as DropdownItem doesn't seem to submit the form properly. */}
                <button
                  type="submit"
                  name="action"
                  value="delete_location"
                  className="dropdown-item"
                  role="menuitem"
                >
                  Delete location
                </button>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </PageHeader>

        <PageHeader className="d-xxl-none mb-2" title="Invoices">
          <Button
            className="icon-btn"
            color="light"
            onClick={() => toggleShowLocations()}
            active={showLocations}
          >
            <FaSliders />
          </Button>

          <Button
            className="icon-btn"
            color="light"
            onClick={() => toggleShowHelp()}
            active={showHelp}
          >
            <FaRegCircleQuestion />
          </Button>
        </PageHeader>

        <MobileLocationsNav
          locations={allLocations}
          selectedLocation={location}
          isOpen={showLocations}
        />

        <Collapse className="d-xxl-none" isOpen={showHelp}>
          <p className="text-secondary">
            You can select specific invoices you&apos;d like to pay if you
            don&apos;t want to pay all outstanding invoices.
          </p>

          <p className="text-secondary">
            Did you change the work done at time of service? You can click the
            plus symbol for any invoice row to add charges for additional
            services or enter a negative amount to reduce your invoice for any
            removed services. Please add a note if a negative amount is entered.
          </p>
        </Collapse>
      </div>

      <div className="d-xl-none bg-white px-3 py-3">
        <div className="mb-3 fs-5 fw-medium text-center">
          <span>Outstanding Balance: </span>
          <span className="text-danger fw-bold">
            ${outstandingBalance.toFixed(2)}
          </span>
        </div>

        <StyledMobileTableHeader>
          <FormGroup check className="mb-0">
            <HeaderSelectionCheckbox
              id="mobileSelectAll"
              selected={selectedInvoiceIds.size}
              total={invoices.length}
              onSelectAll={selectAllInvoices}
              onClearAll={() => selectedInvoiceIds.clear()}
            />{' '}
            <Label check htmlFor="mobileSelectAll" className="fw-medium">
              Select all
            </Label>
          </FormGroup>

          <StyledJumpButton
            color="link"
            onClick={() => summaryTableRef.current?.scrollIntoView()}
          >
            <FaDownLong />
            Jump to total
          </StyledJumpButton>
        </StyledMobileTableHeader>

        <div className="d-flex flex-column gap-3">
          {invoices.map((invoice) => (
            <MobileInvoiceCard
              key={invoice.documentId}
              invoice={invoice}
              isSelected={selectedInvoiceIds.has(invoice.documentId)}
              tipAmount={invoiceTipAmounts.get(invoice.documentId) ?? ''}
              tipPercent={invoiceTipPercents.get(invoice.documentId) ?? ''}
              additionalServicesAmount={
                additionalServiceAmounts.get(invoice.documentId) ?? ''
              }
              additionalServicesNote={
                additionalServiceNotes.get(invoice.documentId) ?? ''
              }
              onToggleSelected={() =>
                toggleInvoiceSelection(invoice.documentId)
              }
              onTipAmountChange={(tipAmount) =>
                invoiceTipAmounts.set(invoice.documentId, tipAmount)
              }
              onTipPercentChange={(tipPercent) =>
                invoiceTipPercents.set(invoice.documentId, tipPercent)
              }
              onAdditionalServiceAmountChange={(amount) =>
                additionalServiceAmounts.set(invoice.documentId, amount)
              }
              onAdditionalServiceNoteChange={(note) =>
                additionalServiceNotes.set(invoice.documentId, note)
              }
            />
          ))}
        </div>
      </div>

      <TableWrapper className="d-none d-xl-block">
        <StyledTable>
          <thead>
            <tr>
              <th>
                <HeaderSelectionCheckbox
                  selected={selectedInvoiceIds.size}
                  total={invoices.length}
                  onSelectAll={selectAllInvoices}
                  onClearAll={() => selectedInvoiceIds.clear()}
                />
              </th>
              <th className="text-nowrap">Work Order #</th>
              <th>Date</th>
              <th>Address</th>
              <th className="text-end">Amount</th>
              <th className="status-column">Status</th>
              {showTips && <th>Add a tip</th>}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice) => (
              <InvoiceRow
                key={invoice.documentId}
                invoice={invoice}
                isSelected={selectedInvoiceIds.has(invoice.documentId)}
                showTips={showTips}
                tipAmount={invoiceTipAmounts.get(invoice.documentId) ?? ''}
                tipPercent={invoiceTipPercents.get(invoice.documentId) ?? ''}
                additionalServicesAmount={
                  additionalServiceAmounts.get(invoice.documentId) ?? ''
                }
                additionalServicesNote={
                  additionalServiceNotes.get(invoice.documentId) ?? ''
                }
                onToggleSelected={() =>
                  toggleInvoiceSelection(invoice.documentId)
                }
                onTipAmountChange={(tipAmount) =>
                  invoiceTipAmounts.set(invoice.documentId, tipAmount)
                }
                onTipPercentChange={(tipPercent) =>
                  invoiceTipPercents.set(invoice.documentId, tipPercent)
                }
                onAdditionalServiceAmountChange={(amount) =>
                  additionalServiceAmounts.set(invoice.documentId, amount)
                }
                onAdditionalServiceNoteChange={(note) =>
                  additionalServiceNotes.set(invoice.documentId, note)
                }
              />
            ))}
          </tbody>
          {showTips && (
            <tfoot>
              <tr>
                <td colSpan={6}></td>
                <td colSpan={2}>
                  <div className="d-flex align-items-center">
                    <ButtonGroup>
                      {TIP_PERCENTS.map((percent) => (
                        <Button
                          key={percent}
                          color="outline-secondary"
                          active={allInvoicesHaveTipPercent(percent)}
                          onClick={() => setTipPercentForAllInvoices(percent)}
                        >
                          {(percent * 100).toFixed(0)}%
                        </Button>
                      ))}
                    </ButtonGroup>
                    <span className="fw-bold ms-3">
                      Add tip to selected payments
                    </span>
                  </div>
                </td>
              </tr>
            </tfoot>
          )}
        </StyledTable>
      </TableWrapper>

      <div className="px-3 px-xl-0">
        <StyledPaymentSummaryTable
          innerRef={summaryTableRef}
          className="mt-4"
          invoiceTotal={invoiceTotal}
          additionalServices={additionalServices}
          tax={salesTax}
          tip={tips}
          showTip={showTips}
          multipleInvoices
        />

        {paymentMethods.length > 1 && (
          <div className="d-flex justify-content-end mt-5">
            <PaymentMethodUncontrolledDropdown>
              <DropdownToggle color="outline-secondary">
                <FaCreditCard className="me-2" />
                {selectedPaymentMethod?.maskedCard ??
                  'Select a payment method...'}
              </DropdownToggle>
              <DropdownMenu end>
                {paymentMethods.map((method) => (
                  <DropdownItem
                    key={method.id}
                    onClick={() => setPaymentMethodId(method.id)}
                  >
                    {method.maskedCard}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </PaymentMethodUncontrolledDropdown>
          </div>
        )}

        <div className="d-flex justify-content-end mt-4">
          <FormGroup check>
            <Input
              type="checkbox"
              name="sendReceipt"
              id="sendReceipt"
              defaultChecked
            />
            <Label for="sendReceipt" check>
              Email me a receipt
            </Label>
          </FormGroup>
        </div>

        {paymentMethods.length === 0 && (
          <div className="d-flex justify-content-end mt-4">
            <p className="text-danger my-0">
              You don&apos;t have any payment methods set up. Please{' '}
              <NavLink
                to="/payment-methods/add"
                className="text-danger-emphasis fw-medium"
              >
                add one
              </NavLink>{' '}
              to continue.
            </p>
          </div>
        )}

        <div className="d-flex justify-content-end mt-4">
          <SubmitButton
            action="pay"
            color="primary"
            size="lg"
            disabled={selectedInvoiceIds.size == 0 || paymentMethodId == null}
          >
            <FaCreditCard /> Pay Selected Invoices
          </SubmitButton>
        </div>
      </div>

      <input
        type="hidden"
        name="paymentMethodId"
        value={paymentMethodId ?? ''}
      />
    </Form>
  );
}

function getInvoiceAmounts(
  invoice: Invoice,
  additionalServiceAmounts: Map<number, string>,
  invoiceTipAmounts: Map<number, string>,
) {
  const balanceDue = invoice.balanceDue - invoice.salesTaxAmount;
  const additionalServices = parseNumberInput(
    additionalServiceAmounts.get(invoice.documentId),
  );
  const tip = parseNumberInput(invoiceTipAmounts.get(invoice.documentId));
  const salesTax =
    invoice.salesTaxAmount + invoice.salesTaxRate * additionalServices;

  return {
    balanceDue,
    additionalServices,
    tip,
    salesTax,
  };
}
