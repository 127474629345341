import { FaGear, FaPlus } from 'react-icons/fa6';
import {
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  UncontrolledDropdown,
} from 'reactstrap';
import styled from 'styled-components';
import { formatLocationAddressShort, Location } from '@/api/locations';
import LinkButton from './LinkButton';
import NavLink from './NavLink';

const SidebarNavLink = styled(NavLink)<{ $isOpen: boolean }>`
  color: var(--bs-gray-800);
  margin-inline: -1rem !important;
  border-radius: 0 !important;

  ${(props) =>
    props.$isOpen
      ? `&.active {
        color: var(--bs-primary) !important;
        background-color: var(--bs-gray-200) !important;
      }`
      : `&.active {
        color: var(--bs-text) !important;
        background-color: transparent !important;
      }`}
`;

export interface MobileLocationsNavProps {
  readonly locations: Location[];
  readonly isOpen: boolean;
  readonly selectedLocation: Location | null;
}

export default function MobileLocationsNav({
  locations,
  isOpen,
  selectedLocation,
}: MobileLocationsNavProps) {
  const indexOfSelectedLocation = locations.findIndex(
    (location) => location.locationId === selectedLocation?.locationId,
  );

  const locationsBeforeSelectedLocation = locations.slice(
    0,
    Math.max(0, indexOfSelectedLocation),
  );

  const locationsAfterSelectedLocation = locations.slice(
    indexOfSelectedLocation + 1,
  );

  return (
    <Nav pills vertical className="mb-3 d-xxl-none">
      <Collapse isOpen={isOpen}>
        {selectedLocation != null && (
          <NavItem>
            <SidebarNavLink to="/invoices" $isOpen={isOpen}>
              All Unpaid Invoices
            </SidebarNavLink>
          </NavItem>
        )}

        {locationsBeforeSelectedLocation.map((location) => (
          <LocationNavItem
            key={location.locationId}
            location={location}
            isOpen={isOpen}
          />
        ))}
      </Collapse>

      {selectedLocation != null ? (
        <LocationNavItem location={selectedLocation} isOpen={isOpen} />
      ) : (
        <NavItem>
          <SidebarNavLink to="/invoices" $isOpen={isOpen}>
            All Unpaid Invoices
          </SidebarNavLink>
        </NavItem>
      )}

      <Collapse isOpen={isOpen}>
        {locationsAfterSelectedLocation.map((location) => (
          <LocationNavItem
            key={location.locationId}
            location={location}
            isOpen={isOpen}
          />
        ))}

        <LinkButton to="/locations/add" className="mt-3">
          <FaPlus /> Add Location
        </LinkButton>
      </Collapse>
    </Nav>
  );
}

interface LocationNavItemProps {
  readonly location: Location;
  readonly isOpen: boolean;
}

function LocationNavItem({ location, isOpen }: LocationNavItemProps) {
  return (
    <NavItem className="position-relative d-flex align-items-center">
      <SidebarNavLink
        className="flex-grow-1"
        to={`/locations/${location.locationId}`}
        $isOpen={isOpen}
      >
        <div>{location.description}</div>
        <div className="text-secondary">
          {formatLocationAddressShort(location)}
        </div>
      </SidebarNavLink>
      <UncontrolledDropdown className="position-absolute end-0">
        <DropdownToggle color="outline-secondary" className="icon-btn">
          <FaGear />
        </DropdownToggle>
        <DropdownMenu end>
          {/* Need to use a normal button here as DropdownItem doesn't seem to submit the form properly. */}
          <button
            type="submit"
            name="action"
            value={`delete_location_${location.locationId}`}
            className="dropdown-item"
            role="menuitem"
          >
            Delete location
          </button>
        </DropdownMenu>
      </UncontrolledDropdown>
    </NavItem>
  );
}
