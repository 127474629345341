import classNames from 'classnames';
import { Table } from 'reactstrap';

export interface PaymentSummaryTableProps {
  readonly innerRef?: React.Ref<HTMLTableElement>;
  readonly className?: string;
  readonly invoiceTotal: number;
  readonly additionalServices: number;
  readonly tax: number;
  readonly tip: number;
  readonly showTip: boolean;
  readonly multipleInvoices?: boolean;
}

export default function PaymentSummaryTable({
  innerRef,
  className,
  invoiceTotal,
  additionalServices,
  tax,
  tip,
  showTip,
  multipleInvoices = false,
}: PaymentSummaryTableProps) {
  const subtotal = invoiceTotal + additionalServices;
  const total = subtotal + tax + tip;

  return (
    <Table innerRef={innerRef} className={className}>
      <tbody>
        <tr>
          <th>Invoice Amount</th>
          <td className="text-end tabular-nums">$ {invoiceTotal.toFixed(2)}</td>
        </tr>
        <tr>
          <th className={additionalServices != 0 ? 'text-danger' : ''}>
            Additional Services
          </th>
          <td
            className={classNames('text-end tabular-nums', {
              'text-danger': additionalServices != 0,
            })}
          >
            $ {additionalServices.toFixed(2)}
          </td>
        </tr>
        <tr>
          <th>Subtotal</th>
          <th className="text-end tabular-nums">$ {subtotal.toFixed(2)}</th>
        </tr>
      </tbody>
      <tbody className="table-group-divider">
        <tr>
          <th>Sales Tax</th>
          <td className="text-end tabular-nums">$ {tax.toFixed(2)}</td>
        </tr>
        {showTip && (
          <tr>
            <th>{multipleInvoices ? 'Tips' : 'Tip'}</th>
            <td className="text-end tabular-nums">$ {tip.toFixed(2)}</td>
          </tr>
        )}
      </tbody>
      <tfoot className="table-group-divider">
        <tr>
          <th>Total</th>
          <td className="text-end tabular-nums fw-bold">
            $ {total.toFixed(2)}
          </td>
        </tr>
      </tfoot>
    </Table>
  );
}
