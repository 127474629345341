import { redirect } from 'react-router-dom';
import { getLocations } from '@/api/locations';

export async function addPaymentMethodLoader() {
  const locations = await getLocations();
  const hasNoLocations = locations.length === 0;

  if (hasNoLocations) {
    return redirect('/payment-methods');
  }
}
