import { useRouteLoaderData } from 'react-router-dom';
import { getLocations } from '@/api/locations';
import { getPaymentMethods, PaymentMethod } from '@/api/paymentMethods';

export interface PaymentMethodsLoaderData {
  hasLocations: boolean;
  paymentMethods: PaymentMethod[];
}

export async function paymentMethodsLayoutLoader() {
  const [locations, paymentMethods] = await Promise.all([
    getLocations(),
    getPaymentMethods(),
  ]);

  return {
    hasLocations: locations.length > 0,
    paymentMethods,
  } satisfies PaymentMethodsLoaderData;
}

export function usePaymentMethodsLoaderData() {
  return useRouteLoaderData<PaymentMethodsLoaderData>('payment-methods')!;
}
