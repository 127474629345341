import { useToggle } from '@uidotdev/usehooks';
import classNames from 'classnames';
import {
  Card,
  CardBody,
  CardFooter,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import { Invoice } from '@/api/invoices';
import { formatLocationAddressShort } from '@/api/locations';
import InputWithPrefixSuffix from '@/components/InputWithPrefixSuffix';
import InvoiceStatusBadge from '@/components/InvoiceStatusBadge';
import TipInput from '@/components/TipInput';
import { formatDate } from '@/utils/dates';
import { formatAdditionalServices, parseNumberInput } from '@/utils/numbers';
import ToggleAdditionalServicesButton from './ToggleAdditionalServicesButton';

export interface MobileInvoiceCardProps {
  readonly invoice: Invoice;
  readonly isSelected: boolean;
  readonly tipAmount: string;
  readonly tipPercent: string;
  readonly additionalServicesAmount: string;
  readonly additionalServicesNote: string;
  readonly onToggleSelected: () => void;
  readonly onTipAmountChange: (tipAmount: string) => void;
  readonly onTipPercentChange: (tipAmount: string) => void;
  readonly onAdditionalServiceAmountChange: (
    additionalServiceAmount: string,
  ) => void;
  readonly onAdditionalServiceNoteChange: (
    additionalServiceNote: string,
  ) => void;
}

export default function MobileInvoiceCard({
  invoice,
  isSelected,
  tipAmount,
  tipPercent,
  additionalServicesAmount,
  additionalServicesNote,
  onToggleSelected,
  onTipAmountChange,
  onTipPercentChange,
  onAdditionalServiceAmountChange,
  onAdditionalServiceNoteChange,
}: MobileInvoiceCardProps) {
  const additionalServices = parseNumberInput(additionalServicesAmount);

  const invoiceSubtotal =
    invoice.balanceDue - invoice.salesTaxAmount + additionalServices;
  const hasAdditionalServices = additionalServices != 0;
  const isAdditionalServicesNoteRequired = hasAdditionalServices;

  const [showAdditionalServices, toggleShowAdditionalServices] =
    useToggle(false);

  return (
    <Card
      className={classNames({
        'opacity-50': !isSelected,
      })}
    >
      <CardBody>
        <div className="mb-3 d-flex align-items-center justify-content-between">
          <FormGroup check className="mb-0">
            <Input
              id={`invoice_selected_${invoice.documentId}`}
              type="checkbox"
              checked={isSelected}
              onChange={onToggleSelected}
              className="mb-1"
            />
            <Label
              check
              htmlFor={`invoice_selected_${invoice.documentId}`}
              className="fw-medium text-decoration-underline flex-grow-1"
            >
              {invoice.workOrderNumber}
            </Label>
          </FormGroup>
          <div className="fw-medium">
            ${invoice.balanceDue.toFixed(2)}
            {hasAdditionalServices && (
              <span className="text-danger">
                {' '}
                {formatAdditionalServices(additionalServices)}
              </span>
            )}
          </div>
          <InvoiceStatusBadge invoice={invoice} />
        </div>

        <FormGroup row>
          <Label xs={4} className="text-secondary fw-medium">
            {invoice.completedDate
              ? formatDate(invoice.completedDate)
              : 'Upcoming'}
          </Label>
          <Label xs={8}>{formatLocationAddressShort(invoice)}</Label>
        </FormGroup>

        {invoice.tipsEnabled ? (
          <FormGroup>
            <Label for={`tip-${invoice.documentId}`}>Add a tip</Label>
            <div className="d-flex align-items-center gap-3">
              <TipInput
                invoiceSubtotal={invoiceSubtotal}
                tipAmount={tipAmount}
                tipPercent={tipPercent}
                onTipAmountChange={onTipAmountChange}
                onTipPercentChange={onTipPercentChange}
              />

              <ToggleAdditionalServicesButton
                hasAdditionalServices={hasAdditionalServices}
                showAdditionalServices={showAdditionalServices}
                onToggleShowAdditionalServices={toggleShowAdditionalServices}
              />
            </div>
          </FormGroup>
        ) : (
          <div className="d-flex justify-content-end">
            <ToggleAdditionalServicesButton
              hasAdditionalServices={hasAdditionalServices}
              showAdditionalServices={showAdditionalServices}
              onToggleShowAdditionalServices={toggleShowAdditionalServices}
            />
          </div>
        )}
      </CardBody>

      {showAdditionalServices && (
        <CardFooter className="bg-white pt-3 pb-3">
          <Label className="fw-medium">Additional Services</Label>

          <InputWithPrefixSuffix
            prefix="$"
            type="number"
            placeholder="0.00"
            step={0.01}
            value={additionalServicesAmount}
            onChange={(event) =>
              onAdditionalServiceAmountChange(event.target.value)
            }
          />

          <Input
            className="mt-2"
            type="text"
            placeholder="Note (required)"
            required={isAdditionalServicesNoteRequired}
            value={additionalServicesNote}
            onChange={(event) =>
              onAdditionalServiceNoteChange(event.target.value)
            }
            disabled={!hasAdditionalServices}
          />
        </CardFooter>
      )}
    </Card>
  );
}
