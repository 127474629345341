import { FormFeedback, FormGroup, InputProps, Label } from 'reactstrap';
import { FormResponse } from '@/api/client';
import InputWithPrefixSuffix from './InputWithPrefixSuffix';

export interface FormInputProps
  extends Exclude<InputProps, 'valid' | 'invalid'> {
  readonly tag?: React.ElementType<InputProps>;
  readonly inputGroup?: boolean;
  readonly actionData?: FormResponse;
  readonly name: string;
  readonly label?: string;
  readonly prefix?: string;
  readonly suffix?: string;
  readonly errorMessage?: string;
  readonly noMargin?: boolean;
}

/**
 * A FormGroup with a Label and an Input that can display validation errors
 * returned from react-router actions.
 */
export default function FormInput({
  tag: Tag = InputWithPrefixSuffix,
  actionData,
  name,
  id = name,
  label,
  children,
  errorMessage,
  noMargin,
  ...props
}: FormInputProps) {
  const hasValidation = actionData?.fieldErrors != null || errorMessage != null;
  const fieldErrors = errorMessage
    ? [errorMessage]
    : actionData?.fieldErrors?.[name];
  const hasErrors = fieldErrors != null && fieldErrors.length > 0;

  const valid = hasValidation && !hasErrors;
  const invalid = hasValidation && hasErrors;

  return (
    <FormGroup noMargin={noMargin}>
      {children}
      {label && <Label for={id}>{label}</Label>}

      <Tag {...props} id={id} name={name} valid={valid} invalid={invalid} />

      {hasErrors && (
        <FormFeedback>
          {fieldErrors.length > 1 ? (
            <ul>
              {fieldErrors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          ) : (
            fieldErrors[0]
          )}
        </FormFeedback>
      )}
    </FormGroup>
  );
}
