import { Badge } from 'reactstrap';
import { getInvoiceStatus, Invoice, InvoiceStatus } from '@/api/invoices';

export interface InvoiceStatusBadgeProps {
  readonly invoice: Invoice;
  readonly className?: string;
}

export default function InvoiceStatusBadge({
  invoice,
  className,
}: InvoiceStatusBadgeProps) {
  const status = getInvoiceStatus(invoice);

  if (status == InvoiceStatus.Pending) {
    return (
      <Badge color="secondary" className={className}>
        Pending
      </Badge>
    );
  } else if (status == InvoiceStatus.Due) {
    return (
      <Badge color="warning" className={className}>
        Due
      </Badge>
    );
  } else if (status == InvoiceStatus.Overdue) {
    return (
      <Badge color="danger" className={className}>
        Overdue
      </Badge>
    );
  } else {
    return null;
  }
}
