import { FaMinus, FaPlus } from 'react-icons/fa6';
import IconButton from '@/components/IconButton';

export interface ToggleAdditionalServicesButtonProps {
  readonly showAdditionalServices: boolean;
  readonly hasAdditionalServices: boolean;
  readonly onToggleShowAdditionalServices: () => void;
}

export default function ToggleAdditionalServicesButton({
  showAdditionalServices,
  hasAdditionalServices,
  onToggleShowAdditionalServices,
}: ToggleAdditionalServicesButtonProps) {
  return (
    <IconButton
      color={hasAdditionalServices ? `outline-danger` : `outline-secondary`}
      onClick={onToggleShowAdditionalServices}
    >
      {showAdditionalServices ? <FaMinus /> : <FaPlus />}
    </IconButton>
  );
}
