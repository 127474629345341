import { ActionFunctionArgs } from 'react-router-dom';
import { removePaymentMethod } from '@/api/paymentMethods';

export default async function paymentMethodDetailsAction({
  params,
}: ActionFunctionArgs) {
  const paymentMethodId = params.paymentMethodId!;

  return removePaymentMethod(paymentMethodId);
}
