import { ActionFunctionArgs } from 'react-router-dom';
import { guestSale } from '@/api/payments';
import { parseNumberInput } from '@/utils/numbers';

export default async function payInvoiceAction({
  request,
}: ActionFunctionArgs) {
  const formData = await request.formData();

  const franchiseId = formData.get('franchiseId') as string;
  const workOrderNumber = formData.get('workOrderNumber') as string;

  const additionalServices = formData.get('additionalServices') as string;
  const additionalServicesNote = formData.get(
    'additionalServicesNote',
  ) as string;
  const tip = formData.get('tip') as string;

  const firstName = formData.get('firstName') as string;
  const lastName = formData.get('lastName') as string;
  const street = formData.get('street') as string;
  const street2 = formData.get('street2') as string;
  const city = formData.get('city') as string;
  const state = formData.get('state') as string;
  const postalCode = formData.get('postalCode') as string;
  const phone = formData.get('phone') as string;
  const email = formData.get('email') as string;

  const paymentKey = formData.get('paymentKey') as string;

  const sendReceipt = formData.get('sendReceipt') === 'on';

  return guestSale({
    franchiseId: parseInt(franchiseId),
    workOrderNumber: parseInt(workOrderNumber),
    additionalServiceAmount: parseNumberInput(additionalServices),
    additionalServicesNote,
    tipAmount: parseNumberInput(tip),
    firstName,
    lastName,
    address: street,
    address2: street2,
    city,
    state,
    postalCode,
    phone,
    email,
    paymentKey,
    sendReceipt,
  });
}
