import { useState } from 'react';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import styled from 'styled-components';
import { InvoiceDetails } from '@/api/invoices';
import FormInput from '@/components/FormInput';
import PaymentSummaryTable from '@/components/PaymentSummaryTable';
import TwoColumnCard from '@/components/TwoColumnCard';
import { parseNumberInput } from '@/utils/numbers';

const TIP_PERCENTS = [0.15, 0.2];

export interface ConfirmAmountCardProps {
  readonly invoice: InvoiceDetails;
}

export default function ConfirmAmountCard({ invoice }: ConfirmAmountCardProps) {
  const [additionalServicesString, setAdditionalServicesString] = useState('');
  const [tipString, setTipString] = useState('');
  const [hasCustomTip, setHasCustomTip] = useState(false);

  const invoiceBalance = invoice.balanceDue - invoice.salesTaxAmount;
  const additionalServices = parseNumberInput(additionalServicesString);
  const tip = parseNumberInput(tipString);

  const subtotalWithAdditionalServices = invoiceBalance + additionalServices;

  const salesTax =
    invoice.salesTaxAmount + additionalServices * invoice.salesTaxRate;

  function onAdditionalServicesChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    setAdditionalServicesString(event.target.value);

    if (!hasCustomTip) {
      const newAdditionalServices = parseNumberInput(event.target.value);
      const tipPercent = tip / subtotalWithAdditionalServices;
      const newSubtotalWithAdditionalServices =
        invoiceBalance + newAdditionalServices;
      const newTipAmount = newSubtotalWithAdditionalServices * tipPercent;

      setTipString(newTipAmount.toFixed(2));
    }
  }

  function onTipChange(event: React.ChangeEvent<HTMLInputElement>) {
    setTipString(event.target.value);
  }

  return (
    <TwoColumnCard title="Confirm Amount">
      <input type="hidden" name="franchiseId" value={invoice.franchiseId} />
      <input
        type="hidden"
        name="workOrderNumber"
        value={invoice.workOrderNumber}
      />

      <p>
        Did you change the work done at time of service? You can add charges for
        additional services or enter a negative amount to reduce your invoice
        for any removed services. Please add a note to indicate the change.
      </p>
      <Row>
        <Col md={6}>
          <FormInput
            name="additionalServices"
            label="Additional Services"
            prefix="$"
            type="number"
            placeholder="0.00"
            step={0.01}
            value={additionalServicesString}
            onChange={onAdditionalServicesChange}
          />
        </Col>
        <Col md={6}>
          <FormInput
            name="additionalServicesNote"
            label="Note"
            placeholder="Note (required)"
            required={additionalServices !== 0}
            disabled={additionalServices === 0}
          />
        </Col>
        {invoice.tipsEnabled && (
          <Col>
            <FormGroup>
              <Label>Tip</Label>
              <div className="d-flex align-items-start gap-3">
                <div className="d-flex gap-3">
                  {TIP_PERCENTS.map((percent) => (
                    <ToggleTipButton
                      key={percent}
                      percent={percent}
                      amount={subtotalWithAdditionalServices}
                      tip={tip}
                      setTipString={setTipString}
                      hasCustomTip={hasCustomTip}
                      setHasCustomTip={setHasCustomTip}
                    />
                  ))}
                  <CustomTipButton
                    setTipString={setTipString}
                    hasCustomTip={hasCustomTip}
                    setHasCustomTip={setHasCustomTip}
                  />
                </div>
                {hasCustomTip ? (
                  <TipFormInput
                    name="tip"
                    prefix="$"
                    suffix={formatTipPercent(
                      subtotalWithAdditionalServices,
                      tip,
                    )}
                    type="number"
                    placeholder="0.00"
                    step={0.01}
                    min={0}
                    value={tipString}
                    onChange={onTipChange}
                    errorMessage={
                      tip > subtotalWithAdditionalServices
                        ? 'Thank you for your generosity! We noticed that the tip exceeds the cost of the job. Was this your intention?'
                        : undefined
                    }
                    noMargin
                  />
                ) : (
                  <input type="hidden" name="tip" value={tipString} />
                )}
              </div>
            </FormGroup>
          </Col>
        )}
      </Row>

      <PaymentSummaryTable
        className="mt-4"
        invoiceTotal={invoiceBalance}
        additionalServices={additionalServices}
        tax={salesTax}
        tip={tip}
        showTip={invoice.tipsEnabled}
      />
    </TwoColumnCard>
  );
}

const StyledTipButton = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover > span,
  &.active > span {
    color: white;
  }
`;

const PercentSpan = styled.span`
  font-size: 18px;
  color: black;
  transition: color 0.15s ease-in-out;
`;

interface ToggleTipButtonProps {
  readonly percent: number;
  readonly amount: number;
  readonly tip: number;
  readonly hasCustomTip: boolean;
  readonly setHasCustomTip: (value: boolean) => void;
  readonly setTipString: (value: string) => void;
}

function ToggleTipButton({
  percent,
  amount,
  tip,
  hasCustomTip,
  setHasCustomTip,
  setTipString,
}: ToggleTipButtonProps) {
  const isActive =
    amount > 0 && !hasCustomTip && Math.abs(tip - amount * percent) < 0.01;

  function onClick() {
    if (isActive) {
      setTipString('');
    } else {
      setHasCustomTip(false);
      setTipString((amount * percent).toFixed(2));
    }
  }

  const percentString = (percent * 100).toFixed(0);
  const amountString = (percent * amount).toFixed(2);

  return (
    <StyledTipButton
      key={percent}
      color="outline-secondary"
      active={isActive}
      onClick={onClick}
    >
      <PercentSpan>{percentString}%</PercentSpan>
      <span>${amountString}</span>
    </StyledTipButton>
  );
}

const TipFormInput = styled(FormInput)`
  max-width: 300px;
`;

interface CustomTipButtonProps {
  readonly hasCustomTip: boolean;
  readonly setHasCustomTip: (value: boolean) => void;
  readonly setTipString: (value: string) => void;
}

function CustomTipButton({
  setTipString,
  hasCustomTip,
  setHasCustomTip,
}: CustomTipButtonProps) {
  function onClick() {
    setTipString('');
    setHasCustomTip(!hasCustomTip);
  }

  return (
    <StyledTipButton
      color="outline-secondary"
      active={hasCustomTip}
      onClick={onClick}
    >
      <PercentSpan>custom</PercentSpan>
    </StyledTipButton>
  );
}

function formatTipPercent(amount: number, tip: number) {
  if (amount > 0 && tip > 0) {
    return ((tip / amount) * 100).toFixed(0) + '%';
  } else {
    return '';
  }
}
