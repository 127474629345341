import { FaCheck } from 'react-icons/fa6';
import { Form, Outlet, useLocation } from 'react-router-dom';
import { Alert, Button } from 'reactstrap';
import styled from 'styled-components';
import { Invitation } from '@/api/invitations';
import AppSidebar from '@/components/AppSidebar';
import LinkButton from '@/components/LinkButton';
import { breakpointUp } from '@/utils/mediaQueries';
import { useDashboardLayoutLoaderData } from './layout.loader';

const SidebarAndContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: stretch;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  padding-inline: 0;
  padding-block: 2rem;

  ${breakpointUp('xl')} {
    padding-inline: 3rem;
  }
`;

const PlaceholderWrapper = styled.div`
  flex-grow: 1;
  width: 100%;
  max-width: 1200px;
  padding: 2rem;
  margin-inline: auto;
`;

export default function DashboardLayout() {
  const location = useLocation();
  const { locations, outstandingBalance, invitations } =
    useDashboardLayoutLoaderData();

  if (locations.length === 0) {
    return (
      <PlaceholderWrapper>
        {invitations.length > 0 && (
          <InvitationsForm invitations={invitations} />
        )}

        <h2>Welcome to Fish!</h2>
        <h4 className="fw-normal">Add a location to get started.</h4>

        <LinkButton
          to="/locations/add"
          color="primary"
          size="lg"
          className="mt-4"
        >
          Add Location
        </LinkButton>
      </PlaceholderWrapper>
    );
  }

  return (
    <SidebarAndContentWrapper>
      <AppSidebar
        locations={locations}
        outstandingBalance={outstandingBalance}
      />
      <ContentWrapper>
        {invitations.length > 0 && (
          <InvitationsForm invitations={invitations} padded />
        )}

        <Outlet key={location.key} />
      </ContentWrapper>
    </SidebarAndContentWrapper>
  );
}

function InvitationsForm({
  invitations,
  padded,
}: {
  readonly invitations: Invitation[];
  readonly padded?: boolean;
}) {
  const invitationNames = invitations
    .map((invitation) => invitation.customerName)
    .join(', ');

  return (
    <Form method="post" action="/invoices">
      <Alert color="info" fade={false} className={padded ? 'mx-3 mx-xl-0' : ''}>
        <p>You&apos;ve been invited to {invitationNames}.</p>
        <div className="d-flex gap-2">
          <Button
            color="primary"
            type="submit"
            name="action"
            value="accept_invitations"
          >
            <FaCheck /> Accept Invite
          </Button>
        </div>
      </Alert>
    </Form>
  );
}
