import { useActionData, useSearchParams } from 'react-router-dom';
import { FormResponse } from '@/api/client';
import FormInput from '@/components/FormInput';
import PasswordInput from '@/components/PasswordInput';
import SubmitButton from '@/components/SubmitButton';
import AuthForm from '@/features/auth/AuthForm';

export default function ResetPasswordPage() {
  const [searchParams] = useSearchParams();
  const actionData = useActionData<FormResponse>();

  const email = searchParams.get('e') ?? '';

  return (
    <AuthForm title="Welcome to Fish!">
      <FormInput
        name="email"
        label="Email"
        required
        defaultValue={email}
        disabled
      />

      <FormInput
        name="firstName"
        label="First Name"
        required
        actionData={actionData}
      />
      <FormInput
        name="lastName"
        label="Last Name"
        required
        actionData={actionData}
      />

      <FormInput
        tag={PasswordInput}
        name="password"
        label="Password"
        required
        actionData={actionData}
      />
      <SubmitButton color="primary" className="w-100 mt-3">
        Accept Invite
      </SubmitButton>
    </AuthForm>
  );
}
