import { useRouteLoaderData } from 'react-router-dom';
import { getInvitations, Invitation } from '@/api/invitations';
import { getInvoices, Invoice } from '@/api/invoices';
import { getLocations, Location } from '@/api/locations';
import { getPaymentMethods, PaymentMethod } from '@/api/paymentMethods';

export interface DashboardLayoutLoaderData {
  locations: Location[];
  invoices: Invoice[];
  outstandingBalance: number;
  paymentMethods: PaymentMethod[];
  invitations: Invitation[];
}

export default async function dashboardLayoutLoader() {
  const [locations, invoices, paymentMethods, invitations] = await Promise.all([
    getLocations(),
    getInvoices(),
    getPaymentMethods(),
    getInvitations(),
  ]);
  const outstandingBalance = invoices.reduce(
    (total, invoice) => total + invoice.balanceDue,
    0,
  );

  return {
    locations,
    invoices,
    outstandingBalance,
    paymentMethods,
    invitations,
  } satisfies DashboardLayoutLoaderData;
}

export function useDashboardLayoutLoaderData() {
  return useRouteLoaderData<DashboardLayoutLoaderData>('dashboard')!;
}
