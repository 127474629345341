import { ActionFunctionArgs } from 'react-router-dom';
import { formError } from '@/api/client';
import { acceptInvite } from '@/api/invitations';

export default async function invitationAction({
  request,
}: ActionFunctionArgs) {
  const searchParams = new URL(request.url).searchParams;

  const formData = await request.formData();
  const email = searchParams.get('e');
  const token = searchParams.get('t');
  const firstName = formData.get('firstName') as string;
  const lastName = formData.get('lastName') as string;
  const password = formData.get('password') as string;

  if (!email || !token) {
    return formError('Invalid invitation link');
  }

  return acceptInvite(email, firstName, lastName, password, token);
}
