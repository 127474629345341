import { useLoaderData } from 'react-router-dom';
import { FindInvoiceResponse, InvoiceDetails } from '@/api/invoices';
import { formatLocationAddressShort } from '@/api/locations';
import sampleInvoice from '@/assets/temporary_placeholder_invoice.png';
import TwoColumnCard from '@/components/TwoColumnCard';
import TwoColumnForm from '@/components/TwoColumnForm';
import FindInvoiceForm from '@/features/invoices/FindInvoiceForm';
import AuthActionsCard from '@/features/payment/AuthActionsCard';
import BillingDetailsCard from '@/features/payment/BillingDetailsCard';
import ConfirmAmountCard from '@/features/payment/ConfirmAmountCard';
import PaymentHistoryCard from '@/features/payment/PaymentHistoryCard';
import usePaymentCardForm from '@/hooks/usePaymentCardForm';
import { formatDate } from '@/utils/dates';

export default function PayInvoicePage() {
  const loaderData = useLoaderData<FindInvoiceResponse>();

  if (!loaderData.invoice) {
    return (
      <FindInvoiceForm
        title="Pay Invoice"
        subtitle="Let's find your invoice! Enter some details from the invoice you'd like to pay."
        method="GET"
        actionLabel="Find Invoice"
        cancelRoute="/"
        showAuthActions
        actionData={loaderData}
      />
    );
  } else {
    return <PayInvoiceForm invoice={loaderData.invoice} />;
  }
}

interface InvoiceFormProps {
  readonly invoice: InvoiceDetails;
}

function PayInvoiceForm({ invoice }: InvoiceFormProps) {
  const { paymentCard, cardError, isBusy, handleSubmit } = usePaymentCardForm();

  return (
    <TwoColumnForm
      title="Pay Invoice"
      subtitle={formatInvoiceTitle(invoice)}
      onSubmit={(event) => void handleSubmit(event)}
      leftColumn={
        <>
          {invoice.payments.length > 0 && (
            <PaymentHistoryCard invoice={invoice} />
          )}

          {invoice.balanceDue > 0 && (
            <>
              <ConfirmAmountCard invoice={invoice} />
              <BillingDetailsCard
                paymentCard={paymentCard}
                cardError={cardError}
                isBusy={isBusy}
              />
            </>
          )}
        </>
      }
      rightColumn={
        <>
          <TwoColumnCard title="Invoice Details">
            <a href={invoice.pdfLink} target="_blank" rel="noreferrer">
              <img
                src={sampleInvoice}
                alt="Invoice PDF"
                className="d-block w-100"
              />
            </a>
          </TwoColumnCard>
          <AuthActionsCard />
        </>
      }
    />
  );
}

function formatInvoiceTitle(invoice: InvoiceDetails) {
  let title = `Work Order #${invoice.workOrderNumber}`;

  if (invoice.completedDate) {
    title += ` on ${formatDate(invoice.completedDate)}`;
  }

  title += ` at ${formatLocationAddressShort(invoice)}`;

  return title;
}
