import { useNavigation } from 'react-router-dom';
import { Button, ButtonProps, Spinner } from 'reactstrap';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  position: relative;
`;

const Overlay = styled.div`
  position: absolute;
  inset: 0;
  background-color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export interface SubmitButtonProps extends ButtonProps {
  readonly action?: string;
  readonly busy?: boolean;
}

/**
 * A Bootstrap button with type="submit" that shows a spinner while react-router
 * is submitting the form. Specific the "action" property to add name="action"
 * and value="{action}" to the button. This allows the router action to
 * differentiate between different buttons. If specified, only the submit
 * button with the action that triggered the form submission will show a busy
 * spinner, but all submit buttons will still be disabled.
 */
export default function SubmitButton({
  action = '',
  children,
  busy,
  disabled = false,
  ...props
}: SubmitButtonProps) {
  const { state, formData } = useNavigation();
  const currentAction = formData?.get('action');
  const isSubmitting =
    formData != null &&
    currentAction === action &&
    (state === 'submitting' || state === 'loading');

  return (
    <StyledButton
      {...props}
      type="submit"
      name={action ? 'action' : undefined}
      value={action}
      disabled={
        disabled || state === 'submitting' || state === 'loading' || busy
      }
    >
      {children}
      {(isSubmitting || busy) && (
        <Overlay>
          <Spinner size="sm" color="light" />
        </Overlay>
      )}
    </StyledButton>
  );
}
