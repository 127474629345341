import { ActionFunctionArgs } from 'react-router-dom';
import { addPaymentMethod } from '@/api/paymentMethods';

export default async function addPaymentMethodAction({
  request,
}: ActionFunctionArgs) {
  const formData = await request.formData();

  const street = formData.get('street') as string;
  const postalCode = formData.get('postalCode') as string;

  const paymentKey = formData.get('paymentKey') as string;
  const maskedCard = formData.get('maskedCard') as string;

  return addPaymentMethod({
    paymentKey,
    maskedCard,
    street,
    postalCode,
  });
}
